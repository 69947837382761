export const eurasia = [
  'Afghanistan',
  'Albania',
  'Andorra',
  'Armenia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Belarus',
  'Belgium',
  'Bhutan',
  'Bosnia and Herzegovina',
  'Brunei',
  'Bulgaria',
  'Cambodia',
  'China',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'East Timor',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Korea, North',
  'Korea, South',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malaysia',
  'Maldives',
  'Malta',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Myanmar',
  'Nepal',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'San Marino',
  'Saudi Arabia',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sri Lanka',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Thailand',
  'Turkey',
  'Turkmenistan',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uzbekistan',
  'Vietnam',
  'Yemen',
  'Abkhazia',
  'Artsakh',
  'Kosovo',
  'Northern Cyprus',
  'South Ossetia',
  'Taiwan',
  'Transnistria',
];
